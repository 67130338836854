// src/EditableDropdown.js
import React, { useEffect, useState } from "react";

const EditableDropdown = ({
  section,
  setSection,
  setSelectedSection,
  setCheckedItems,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [newOption, setNewOption] = useState("");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleButtonClick = () => {
    setIsEditing(true);
  };
  const handleClose = () => {
    setIsEditing(false);
  };

  const handleInputChange = (event) => {
    setNewOption(event.target.value);
  };

  const handleAddSection = () => {
    if (newOption.trim() && !options.includes(newOption.trim())) {
      setOptions([...options, newOption.trim()]);
      setSection({ ...section, [newOption.trim()]: [] });
      setSelectedOption(newOption.trim());

      setNewOption("");
      setIsEditing(false);
    } else if (newOption.trim().length) {
      alert(`${newOption.trim()} already added.`);
    }
  };

  const handleSectionSelection = (selectedOption) => {
    if (selectedOption.length > 0) {
      console.log("selectedOption: " + selectedOption);
      setSelectedSection(selectedOption);
    }

    // setSection({ ...section, [selectedOption]: [] });
  };
  useEffect(() => {
    handleSectionSelection(selectedOption);
    console.log(section);
  }, [selectedOption]);

  return (
    <div className="flex">
      {isEditing ? (
        <div className="flex-1 justify-center justify-items-center">
          <input
            type="text"
            className="p-2 rounded-s focus:outline-none"
            value={newOption}
            onChange={handleInputChange}
            placeholder="Enter new section"
          />
          <button
            className="p-2 text-white font-semibold bg-lime-600 rounded-e"
            onClick={handleAddSection}
            disabled={newOption.length ? false : true}
          >
            Add Section
          </button>
          <button
            className="text-center text-2xl text-red-600 font-bold  rounded-full ms-1 items-center p-2"
            onClick={handleClose}
          >
            X
          </button>
        </div>
      ) : (
        <div>
          <select
            className="p-2  rounded-s focus:outline-none"
            value={selectedOption}
            onChange={handleSelectChange}
          >
            <option value="" disabled>
              {`${options.length ? "Select" : "Create"} a section`}
            </option>
            {options.map((option, index) => (
              <option key={index} value={option} className="text-slate-800">
                {option}
              </option>
            ))}
          </select>
          <button
            className="p-2 text-white font-semibold bg-lime-600 rounded-e"
            onClick={handleButtonClick}
          >
            Add Section
          </button>
        </div>
      )}
    </div>
  );
};

export default EditableDropdown;
