import React, { useEffect, useState } from "react";

const Home = ({
  currentStep,
  courses,
  classes,
  subjects,
  chapters,
  topics,
  selectedCourse,
  selectedClasses,
  selectedSubject,
  selectedChapter,
  selectedTopic,
}) => {
  const [homeCourse, setHomeCourse] = useState([]);
  const [homeClass, setHomeClass] = useState([]);
  const [homeSubject, setHomeSubject] = useState([]);
  const [homeChapter, setHomeChapter] = useState([]);
  const [homeTopic, setHomeTopic] = useState([]);
  console.log("selectedChapter", selectedChapter);
  useEffect(() => {
    setHomeCourse(
      courses.filter((item) => item.courseId === selectedCourse[0])
    );
  }, [selectedCourse]);

  useEffect(() => {
    setHomeClass(classes.filter((item) => item.classId === selectedClasses[0]));
  }, [selectedClasses]);

  useEffect(() => {
    console.log(subjects);
    setHomeSubject(
      subjects.filter((item) => selectedSubject.includes(item.subjectId))
    );
  }, [selectedSubject.length]);

  useEffect(() => {
    console.log("chapters", chapters);
    setHomeChapter(
      chapters.filter((item) => selectedChapter.includes(item.chapterId))
    );
  }, [selectedChapter.length]);

  console.log("homeChapter", homeChapter);
  console.log("SelectedChapter", selectedChapter);

  // course
  // const homeCourse = courses.filter(
  //   (item) => item.courseId === selectedCourse[0]
  // );
  // console.log("Home Course", homeCourse);
  // class

  // const homeClass = classes.filter(
  //   (item) => item.classId === selectedClasses[0]
  // );
  // subject
  // const homeSubject = subjects.filter((item) =>
  //   selectedSubject.includes(item.subjectId)
  // );

  // chapters
  // const homeChapter = chapters.filter((item) =>
  //   selectedChapter.includes(item.chapterId)
  // );
  // topic
  // const homeTopic = topics.filter((item) =>
  //   selectedTopic.includes(item.topicId)
  // );

  const sections = [
    {
      title: "Course",
      content:
        homeCourse.length && currentStep >= 0
          ? homeCourse[0].courseName
          : "Course Name",
    },
    {
      title: "Class",
      content:
        homeClass.length && currentStep >= 1
          ? homeClass[0].className
          : "Class Name",
    },
    {
      title: "Subject",
      content:
        homeSubject.length && currentStep >= 2 ? homeSubject : "Subject Name",
    },
    {
      title: "Chapter",
      content:
        homeChapter.length && currentStep >= 3 ? homeChapter : "Chapter Name",
    },
    {
      title: "Topic",
      content: homeTopic.length && currentStep >= 4 ? homeTopic : "Topic Name",
    },
  ];

  // useEffect(() => {
  //   console.log("selected Subject:", selectedSubject);
  // }, [sections]);

  return (
    // <div className="bg-[#206CA2]   md:mt-3 shadow-lg shadow-black   rounded-md">
    //   {sections.map((section, index) => (
    //     <div
    //       key={index}
    //       className={`p-3 cursor-pointer border-l-2 mt-2  ${
    //         index === currentStep ? "border-yellow-300" : "border-transparent"
    //       }`}
    //     >
    //       <h1 className="text-yellow-300 font-semibold ml-2">
    //         {section.title}
    //       </h1>
    //       {/* <p className="text-white ml-2">{section.content}</p> */}

    //       {currentStep > 1 && Array.isArray(section.content) ? (
    //         section.content.map((item, index) => (
    //           <p key={index} className="text-white ml-2">
    //             {item.subjectName}
    //           </p>
    //         ))
    //       ) : (
    //         <p className="text-white ml-2">{section.content}</p>
    //       )}
    //     </div>
    //   ))}
    // </div>

    <div className="bg-[#206CA2]   md:mt-3 shadow-lg shadow-black   rounded-md">
      {sections.map((section, index) => (
        <div
          key={index}
          className={`p-3 cursor-pointer border-l-2 mt-2  ${
            index === currentStep ? "border-yellow-300" : "border-transparent"
          }`}
        >
          <h1 className="text-yellow-300 font-semibold ml-2">
            {section.title}
          </h1>
          {/* <p className="text-white ml-2">{section.content}</p> */}

          {currentStep > 1 && Array.isArray(section.content) ? (
            section.content.map((item, index) => (
              <p key={index} className="text-white ml-2">
                {item.subjectName}
              </p>
            ))
          ) : (
            <p className="text-white ml-2">{section.content}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default Home;
